import { najaAjax } from '../../utils/ajax'
import { triggerEvent } from '../../utils/dom'

let timer = null
let request = null

export function submitForm(form) {
    if (!form) {
        return
    }

    if (request) {
        request.abort()
    }

    if (form.dataset.autosubmit === 'ajax') {
        request = najaAjax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: new FormData(form),
            najaOptions: {
                element: form
            }
        })
    } else {
        form.submit()
    }
}

document.addEventListener('nette:autosubmit', event => {
    if (event.defaultPrevented) {
        return
    }

    submitForm(event.target)
})

document.addEventListener('change', event => {
    const form = event.target.closest('form') || event.target.form
    let shouldAutosubmit = false

    if (form?.dataset?.autosubmit != null || (event.target.dataset?.autosubmit != null && event.target.dataset?.autosubmit !== "false")) {
        shouldAutosubmit = true
    }

    if (event.target.closest('[data-autosubmit="false"]')) {
        shouldAutosubmit = false
    }

    if (shouldAutosubmit) {
        clearTimeout(timer)
        let delay = parseInt(event.target.closest('[data-autosubmit-delay]')?.dataset?.autosubmitDelay)

        if (isNaN(delay)) {
            delay = 500
        }
        timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), delay)
    }
})

document.addEventListener('keyup', event => {
    const form = event.target.closest('form') || event.target.form
    let shouldAutosubmit = false

    if (form?.dataset?.autosubmit != null || (event.target.dataset?.autosubmit != null && event.target.dataset?.autosubmit !== "false")) {
        shouldAutosubmit = true
    }

    if (event.code === 'Tab') {
        shouldAutosubmit = false
    }

    if (event.target.closest('[data-autosubmit="false"]') || event.target.closest('.Select')) {
        shouldAutosubmit = false
    }

    if (shouldAutosubmit) {
        clearTimeout(timer)
        let delay = parseInt(event.target.closest('[data-autosubmit-delay]')?.dataset?.autosubmitDelay)
        if (isNaN(delay)) {
            delay = 500
        }
        timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), delay)
    }
})