import ScrollService from './NativeScroll'
// import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = () => {
    const header = document.querySelector('.Header')
    if (!header) {
        return 100
    }
    return header.offsetHeight - 1
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)
export const setScrollPosition = (x = null, y = null) => {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}


export default ScrollService