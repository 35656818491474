import { transformData } from "./transformData"

export function ajax(options = {}) {
    options = {
        url: '',
        data: {},
        fetchOptions: {},
        dataType: 'json',
        before: () => { },
        success: () => { },
        error: () => { },
        complete: () => { },
        ...options
    }
    options.fetchOptions = {
        method: 'GET',
        ...options.fetchOptions,
    }

    const controller = new AbortController()
    const { signal } = controller

    const run = async () => {
        try {
            let result = null

            options.before()

            const response = await fetch(options.url, {
                signal,
                body: transformData(options.url, options.fetchOptions.method, options.data),
                ...options.fetchOptions,
            })

            const dataType = options.dataType.toLowerCase()
            if (dataType === 'json') {
                result = await response.json()
            } else {
                result = await response.text()
            }
            options.success(result)
            return result
        } catch (error) {
            options.error(error)
        } finally {
            options.complete()
        }
    }

    const abort = () => {
        controller.abort()
    }

    return {
        promise: run(),
        abort
    }
}